import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ description, lang, meta, pathname }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
            social {
              twitter
            }
            imageShare
          }
        }
      }
    `
  )

  const url = site.siteMetadata.url
  const metaDescription = site.siteMetadata.description
  const image = `${site.siteMetadata.url}/${site.siteMetadata.imageShare}`
  let path = pathname === `I'm Irvin,` ? " " : "| " + pathname
  const title = `${site.siteMetadata.title} ${path}`

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href="https://irviniveslau.com/" />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaDescription} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:creator" content={site.siteMetadata.social.twitter} />
      <meta name="keywords" content="irvin, irvin ives lau, ives, lau, irvin lau, irvin.dev, irvin.va, frontend, software engineer, engineer, developer, react, reactjs" />
      <meta name="google-site-verification" content="Q_t-Lje8DEuKCC9wetiwSre_slF90VnqM4hRsPgfXns" />
      
      <html lang={lang} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
