import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"

const Burger = () => {
  return (
    <Menu right width={"100%"}>
      <Link className="nav-item" to="/">
        Home
      </Link>
      <Link className="nav-item" to="/portfolio">
        Portfolio
      </Link>
      <Link className="nav-item" to="/visualarts">
        Visual Arts
      </Link>
      <Link className="nav-item" to="/about">
        About
      </Link>
      <Link className="nav-item" to="/contact">
        Contact
      </Link>
    </Menu>
  )
}

export default Burger
