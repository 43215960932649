import React from "react"
import { Link } from "gatsby"

const Navbar = () => {
  return (
    <header>
      <nav>
        <ul className="nav-list">
          <li>
            <Link className="nav-item" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="nav-item" to="/portfolio">
              Portfolio
            </Link>
          </li>
          <li>
            <Link className="nav-item" to="/visualarts">
              Visual Arts
            </Link>
          </li>
          <li>
            <Link className="nav-item" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="nav-item" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default Navbar
