import React, {useEffect, useState} from "react"
import Particles from "react-particles-js"

const ParticlesJS = () => {
  const [screenWidth, setScreenWidth] = useState()

  useEffect(() => {
    if (window) {
      const newScreenWidth = window.innerWidth > 0 ? window.innerWidth : window.screen.width;
      setScreenWidth(newScreenWidth);
    }
  }, [])

  const particleOptions = {
    particles: {
      line_linked: {
        enable: true,
        distance: screenWidth > 480 ? 170 : 85,
        color: "#cbcbcb",
      },
      color: {
        value: "#cbcbcb",
      },
      size: {
        value: screenWidth > 480 ? 4 : 2,
      },
    },
  }


  return <Particles params={particleOptions} />
}

export default ParticlesJS
